div.scheduleTimeSelector > div.rdt > div.rdtPicker > div.rdtMonths > table > thead {
  visibility: collapse;
}

.hasItems,
div.hasItems {
  background-color: #afa;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected.hasItems {
  background-color: #428bca;
  color: white;
}
