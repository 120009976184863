.ui.padded.table div.recordingEventTimeEdit td,
.ui.padded.table div.recordingEventTimeEdit th {
  padding: 0;
}

.recordingEventTimeEditConfirmLine {
  display: flex;
}

.ui.table tbody tr td.selectable:hover {
  cursor: pointer;
}
