.PlaylistFrameClipPreview {
  height: 100%;
  background-color: green;
  border: 1px solid black;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.7;
  pointer-events: none;
}

.PlaylistFrameClipPreview.colliding {
  background-color: red;
}

.PlaylistFrameClipPreview.unknownDuration {
  background-color: yellow;
}

.PlaylistFrameClipPreview.checkHover {
  display: none;
}

.PlaylistRegion.Timeline:hover .PlaylistFrameClipPreview.checkHover {
  display: inline-block;
}
