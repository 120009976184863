#CloudServicesApp {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#CloudServicesTabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#LocalConfigPane,
#CloudPortalPane {
  height: 100%;
}

#CloudPortalPane {
  display: flex;
  align-items: center;
  justify-content: center;
}

#CloudAdminFrame {
  width: 100%;
}
