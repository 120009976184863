div.scheduleTableContainer {
  overflow-y: auto;
  margin: 0;
  margin-left: -3px;
  flex-grow: 1;
  flex-basis: 0;
}

tr.tableBlockLabel {
  font-weight: bold;
  background-color: #7fb3cc;
  font-size: 1.1em;
  //text-shadow: -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white, 1px 1px 0 white;
}

tr.tableBlockItem {
  background-color: #f1fbfd;
  font-size: 1.1em;
}

.ui.table tr.tableBlockLabel td {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.ui.table tr.tableBlockLabel > td > div.ui.checkbox {
  vertical-align: text-bottom;
}

.ui.table.celled tr.tableBlockItem > td:first-child {
  border-left: 4px solid #7fb3cc;
}

.ui.table.celled tr.tableBlockItem > td:last-child {
  border-right: 4px solid #7fb3cc;
}

tr.empty {
  background-color: #b8bbb9;
  font-size: 1em;
}

tr.tableBlockItem.empty {
  background-color: #a7b9bd;
}

tr.empty > td.scheduleItemLabel {
  color: #535050;
}

tr.isPlaying {
  font-weight: bold;
  color: darkgreen;
  outline: solid 1px #4bb64a;
}

tr.isPlaying > td.scheduleItemTime {
  background-color: #4bb64a;
  color: white;
}

span.startTimeLabel,
span.endTimeLabel,
span.scheduleItemDuration {
  white-space: nowrap;
}

tr.frontError > td.scheduleItemTime,
tr.rearError > td.scheduleItemTime {
  background-color: #d9002e;
  color: white;
}

tr.dneError > td.scheduleItemLabel {
  background-color: #d9002e;
  color: white;
}

tr.frontError span.endTimeLabel {
  font-weight: bold;
}

tr.rearError span.startTimeLabel {
  font-weight: bold;
}

tr.tableBlockLabel.error > td.blockTime {
  background-color: #d9002e;
  color: white;
  font-weight: bold;
}

tr.selected {
  background-color: #dfd;
}

tr.tableBlockLabel.selected {
  background-color: #5f5;
}

tr.tableBlockItem.selected {
  background-color: #9f9;
}

.ui.table.celled tr.tableBlockItem.blockSelected > td:first-child {
  border-left-color: #5f5;
}

.ui.table.celled tr.tableBlockItem.blockSelected > td:last-child {
  border-right-color: #5f5;
}

.ui.label > div.detail.scte35Detail {
  display: block;
  margin-left: 0;
}

.ui.buttons.attached.blockQuickButtons {
  z-index: auto;
}

/*
 * So the table REALLY annoyed me that it had 1 pixel of white space. This should eradicate this problem via the pits of hell.
 */
table.ui.celled.compact.sixteen.column.table {
  margin: 0;
}

.ui.table tr.error.tableBlockLabel {
  background-color: #ffd6d6 !important;
}

.ui.table tr.error.selected {
  background-color: #fcc !important;
}

.ui.table tr.error.tableBlockLabel.selected {
  background-color: #f99 !important;
}
