#RecordingControls {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#RecordingControlsTable {
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
  margin: 0.5em;
}

#RecordingControlsHeader {
  margin: 0.5em;
}
