#SettingsHeader {
  margin: 0.5em;
}

#ServicesList {
  padding: 0.5em;
}

.ui.icon.menu .item.serviceDeleteButton {
  color: #fff;
  background-color: #db2828;
}
