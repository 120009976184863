.FileTransfer {
  text-align: center;
}

.FileTransfer-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.FileTransfer-title {
  font-size: 1.5em;
}

.FileTransfer-intro {
  font-size: large;
}

@keyframes FileTransfer-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#download-from {
  text-align: left;
}

a.transferLink {
  color: inherit;
}
