#create {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#create_menu {
  display: inline-block;
}
