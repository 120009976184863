div#libraryApplicationPane {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

div#libraryFileList {
  height: 0;
  flex-grow: 1;
}

/*
 * The button navbar is ugly when it's not flexing across the screen- but now it's a youtube rapper so I'm not sure if this is a good change.
 */
#library-navbar {
  display: flex;
}
.ui.menu#library-navbar .item.fitted::before {
  display: none;
}

#library-navbar .ui.button {
  margin: 3px;
}
