#UploadApp {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50ch, 1fr));
  grid-row-gap: 0.5em;
  overflow: hidden;
}

#FileTransferContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 0;
  padding: 0 0.5em 0.5em 0.5em;
}

#FileTransfers {
  flex-grow: 1;
  overflow-y: auto;
}

#UploadControlsContainer {
  padding: 0 0.5em 0.5em 0.5em;
  width: 100%;
}

#fileTransfersHeader {
  margin: 0.5em;
}

#transfersClearAll {
  flex-shrink: 0;
}
