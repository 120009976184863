div#LogsTabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.logsSection {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

div.logsTextContainer {
  height: 0;
  flex-grow: 1;
  overflow: auto;
  white-space: nowrap;
}

div.ui.segment.logsText {
  display: inline-block;
  min-width: 100%;
}
