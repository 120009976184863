#GlobalMediaEditor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#GlobalMediaEditor > .ui.header {
  margin: 0.5em;
}

#GlobalMediaManager {
  flex-grow: 1;
  display: flex;
}

#GlobalMediaLibraryContainer,
#GlobalMediaControlsContainer {
  flex-grow: 1;
  flex-basis: 0;
}

#GlobalMediaControlsContainer {
  margin-left: 1em;
}
