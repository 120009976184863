#GlobalMediaControls {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#GlobalMediaControlButtons {
  display: flex;
  align-items: center;
}

.spacer {
  flex-grow: 1;
}

#GlobalMediaRegionContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}
