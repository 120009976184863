#ServicePage {
  display: flex;
  flex-direction: column;
  height: 100%
}

#ServicesList {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.SettingsTabContents {
  overflow: auto;
  height: 100%;
}

#SettingsDiagnostics {
  margin-left: 0.5em;
  margin-top: 0.5em;
}
