.ui.form .fields.RequestFormSelectMultipleGroup {
  flex-wrap: wrap;
}

.ui.form .fields.RequestFormGroup {
  margin-bottom: 1em!important;
}

.ui.form .fields.grouprequired > label:after {
  display: inline-block;
  vertical-align: top;
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828;
}
