.frameEditorPanel {
  height: 100%;
  border: solid 1px black;
  background-color: black;
}

#ActionSafeBoundary,
#TitleSafeBoundary {
  position: absolute;
  color: white;
  pointer-events: none;
  visibility: hidden;
}

div.BoundaryBorder {
  position: absolute;
  z-index: 9999;
  background: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: solid 1px rgba(255, 255, 255, 0.4);
}

#ActionSafeBoundary > span,
#TitleSafeBoundary > span {
  position: absolute;
  left: 0.5em;
  bottom: 0;
  z-index: 0;
}

.frameEditorPanel:hover > #ActionSafeBoundary,
.frameEditorPanel:hover > #TitleSafeBoundary,
#ActionSafeBoundary.show,
#TitleSafeBoundary.show {
  visibility: visible;
}

#ActionSafeBoundary {
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
}

#TitleSafeBoundary {
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
}
