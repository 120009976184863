.PlaylistViewIndicator {
  position: absolute;
  height: 100%;
  width: 7px;
  background-color: red;
  pointer-events: none;
}

.PlaylistViewIndicator.preview {
  background-color: lime;
  display: none;
}

#PlaylistTimelineContents:hover .PlaylistViewIndicator.preview {
  display: initial;
}

.PlaylistViewIndicatorLine {
  position: absolute;
  left: calc(48% - 1px);
  top: 100%;
  width: 3px;
  border-left: 1px red solid;
  border-right: 1px red solid;
  height: 100vh;
  background-color: inherit;
  z-index: 1000;
}

.preview .PlaylistViewIndicatorLine {
  border-left-color: lime;
  border-right-color: lime;
  background-color: black;
}
