/*
 * The search bar was tiny and annoying and puny, so we removed it's walls and made it wide. 
 * Now it's plainly visible and actually kinda good looking
 */

.ui.search.librarySearch {
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    margin-left: 1em;
}
.ui.icon.input {
    width: 100%;
}
