.FrameEditor {
  margin: auto;
  position: relative;
  max-width: 90vh;
}

.ratio4_3 {
  padding-bottom: 75%;
}

.ratio16_9 {
  padding-bottom: 56.25%;
}

.ratio3_2 {
  padding-bottom: 66.66%;
}

.ratio8_5 {
  padding-bottom: 62.5%;
}

.FrameEditorContent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.FrameEditorContainer {
  padding: 1em;
  overflow: hidden;
}
