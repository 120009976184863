#PlaylistTimeBar {
  flex-grow: 2;
  background-color: lightgrey;
  position: relative;
}

#PlaylistTimeBar:hover {
  cursor: pointer;
}

#PlaylistTimeIndicators {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}

#PlaylistTimeIndicatorsPositioner {
  position: absolute;
  height: 100%;
  top: 0;
}
