.ui.menu a.item.panic {
  background-color: red;
  animation: 0.3s infinite alternate panic;
}

@keyframes panic {
  from {
    background-color: #f00;
  }

  to {
    background-color: #700;
  }

}
