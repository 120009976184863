div#scheduleEditor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

div#schedulePanel,
div#scheduleFilePanel {
  width: 0;
  border: 1px solid grey;
}

div#schedulePanel {
  flex-grow: 3;
  overflow-x: auto;
}

div#scheduleFilePanel {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

div#scheduleLibraryContainer {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
}

.filePanelTab {
  flex-grow: 1;
  overflow-y: auto;
}
