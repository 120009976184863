div.EventControls {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

div.EventControlsSpacer {
  flex-grow: 1;
}

.EventOccurence.noselect:hover {
  cursor: pointer;
  color: blue;
}

.eventAssignedSegment {
  overflow-x: hidden;
  word-break: break-word;
}

.eventAssignedSegment:hover {
  cursor: default;
}
