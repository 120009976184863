.scrollingTextPreview {
    margin-top:15px;
    background-image: url("/checker8.png");
    border: 1px solid #cccccc;
    height: 100px;
    width: 100% !important;
    overflow: hidden;
    position: absolute;
}

.scrollingTextPreview__test {
  font-size: 40px;
  position: absolute;
  opacity: 0;
}

.scrollingTextPreview__marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    height:50px;
    padding-top: 15px;
}

.scrollingTextPreview__marquee span {
    display: inline-block;
    position: relative;
    font-size: 40px;
    animation: scrollingTextPreview__marquee 20s linear infinite;
}
/* Make it move */
@keyframes scrollingTextPreview__marquee {
    0%   { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

.scrollingTextPreview__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
