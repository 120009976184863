.ui.menu .dropdown.item .menu#menuDropdown {
  display: none;
}

.ui.menu .dropdown.item .menu.visible#menuDropdown {
  position: absolute;
  display: flex!important;
  flex-direction: column!important;
  flex-wrap: wrap;
  max-height: 75vh;
  align-items: flex-start;
}
