.scheduleDefaultsPanel .ui.segment {
  padding: 0.5em;
}

.ui.grid.scheduleDefaultsPanel > .row > .column {
  padding: 2px;
}

.ui.container.scheduleDefaultsTextContainer {
  display: flex;
}

.scheduleDefaultsText {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex-grow: 1;
}

.scheduleDefaultsRemoveIcon:hover {
  cursor: pointer;
}
