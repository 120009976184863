div.libraryComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1px;
  padding-right: 1px;
  position: relative;
}

#searchbar-container {
  width: 100%;
}

div.libraryNavBar {
  display: flex;
  flex-wrap: wrap;
}

div.libraryNavSpacer {
  flex-grow: 1;
}
