.textGrid--row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;

}

.textGrid--m0 {
    margin: 0px !important;
}
.textGrid--Buttons {
    padding-top: 20px !important;
} 

.textGrid--w100 {
    width: 100%;
}
.fields {
    margin-bottom:0px !important;
}
.textGrid--segments {
    border: 0px !important;
    margin: 0px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.textGrid--segment-right {
    border: 0px !important;
    padding: 0px !important;
    box-shadow: 0px !important;
    -webkit-box-shadow: 0px !important;
    width: 65% !important;
    position: relative;
}
.textGrid--segment-left {
    font-size: 15px !important;
    font-weight: 400;
    border: 0px !important;
    padding: 10px 0px 0px 0px !important;
    box-shadow: 0px !important;
    -webkit-box-shadow: 0px !important;    
    width: 35% !important;
}

.scrollinText--input {
    width: 100% !important;
}
.scrollinText--dropdown {
    position: absolute !important;
    margin-top: 0px !important;
    right:0px !important;
    min-width: 10em !important;
    max-width: 12em !important;
}
