div#authenticationPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#authenticationForm {
  margin: auto;
  padding: 25px;
  max-width: 450px;
  width: 90%;
  text-align: center;
  border: 1px grey solid;
}

div.authenticationInput {
  text-align: left;
}

div.authenticationInput > label {
  padding-left: 1em;
}

img#clientImage {
  max-width: 100%;
}

@media(max-width: 400px) {
  h1#authenticationHeader {
    font-size: 1.4rem;
  }
}
