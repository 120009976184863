.rssItemEditor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rssItemEditor__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.rssItemEditor__MainContainer {
    padding:10px;
    flex-grow: 1;
    height: 100%;
}

.rssItemEditor__breadcrumb a {
    font-size: 11px;
}
.rssItemEditor__breadcrumb .active {
    font-size: 11px;
}
.rssItemEditor__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}

.rssItemEditor__Row {
    display:flex; 
    flex-direction: row;
    height: 100%;
}

.rssItemEditor--div {
    width: 50% !important;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.rssItemEditor--textarea {
    resize: none;
}

.rssItemEditor--url {
    margin-bottom: 15px;
    width: 95%;
}

.rssItemEditor__Row textarea {
    border: 1px solid #CCC;
    padding: 10px;
    width: 95%;
    height: 68%;
    resize: none;
}
.rssItemEditor__Row button {
    white-space: nowrap !important;
}
.rssItemEditor--previewDiv {
    position: relative;
    width: 95%;
}
.rssItemEditor__buttonGroup {
    margin-top: 10px !important; 
    padding-top: 10px !important; 
    border-top: 1px solid #cccccc;
}
.rssItemEditor__buttonGroup button{
    margin-right: 10px !important;
}
.rssItemEditor__errorText {
    border: 1px solid #990000;
    background: #fadbdb;
    padding: 6px;
    margin: 10px 0px 20px 0px;
}

.textGridContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}

@media screen and (max-width:1024px) {
    .rssItemEditor__Row { flex-direction: column;  }
    .rssItemEditor--div {
        width: 100%;
        position: inherit;
        margin-bottom: 20px;
        flex-basis: 0;
        flex-grow: 1;
    }
    .rssItemEditor--textarea { height: 400px; }
    .rssItemEditor--previewDiv { width: 100%; }
    .rssItemEditor__Row textarea {
        height: 300px;
        width: 100%;
    }
    .rssItemEditor--url {
        width: 100%;
    }
}
