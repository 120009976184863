div.audioMetersContainer {
  margin-top: 2px;
}

div.audioMeter {
  height: 0.5em;
  border: solid black 1px;
}

div.audioMeterBar {
  background-image: linear-gradient(to right, blue, green 33%, yellow 64%, red 100%);
  height: 100%;
}
