div.PlaylistRegionName {
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

div.ui.PlaylistRegionName input {
  padding: 1px;
}

button#PlaylistAddRegionButton {
  height: 100%;
  border-radius: 0;
}

div.PlaylistRegionRow.selected div.PlaylistRegion.Sidebar {
  background-color: #cfc;
}
