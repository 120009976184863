.timeMarker {
  height: 50%;
  position: absolute;
}

.timeMarkerLabel {
  position: relative;
  right: 50%;
  top: 60%;
}
