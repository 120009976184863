div.libraryTilesPanel {
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1em;
  flex-direction: column;
}

div.tileIcon {
  height: 10vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

div.tileIcon img.ui.image {
  max-height: 100%;
}

.tileHeader {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.container.tileTypeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

div.tileImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.ui.green.card > .content {
  outline: 2px #21ba45 solid;
}
