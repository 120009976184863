div.libraryTablePanel {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

div.libraryTableBody {
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}

div.libraryTableBody > table {
  table-layout: fixed;
}

td.cellWordBreak {
  word-break: break-all;
}

td.cellNoOverflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

tbody.libraryTableHeaderSizer {
  visibility: collapse;
}
