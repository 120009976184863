#sidebar-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.ui.sidebar-tab-pane {
  height: 0;
  flex-grow: 1;
  padding: 0;
}

#channel-sidebar-contents,
#output-sidebar-contents,
#input-sidebar-contents {
  border-radius: 0;
  height: 100%;
  overflow-y: auto;
  border: 0;
  display: block;
  background-color: #565656;
}

.ui.image.monitorImage {
  display: none;
}

.monitorPlaying {
  overflow:hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.ui.segment.widgetBarHamburger {
  padding: 0;
}

.ui.segment.widgetBarHamburger > .ui.fluid.dropdown {
  text-align: center;
}
