.RegionContents {
  display: flex;
  flex-wrap: wrap;
}

.RegionLabel,
.RegionItem {
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.RegionControls {
  display: inline-block;
  flex-basis: 0;
  flex-grow: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.RegionControls,
.RegionItem {
  justify-content: center;
}

.RegionDeleteButton {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.RegionName {
  vertical-align: text-bottom;
  padding-left: 0.5em;
}
