.ui.label.SettingCheckboxLabel {
  margin-right: 1em;
}

.ui.toggle.checkbox {
  vertical-align: text-top;
}

.Setting.Description {
  padding: 0.5em 0 0 0.5em;
  font-weight: normal;
  font-size: 14px;
  line-height: 1em;
}

.SettingsDropdown {
  display: flex;
}

.SettingsDropdown > .ui.selection.dropdown {
  flex-grow: 1;
  width: 0;
}
