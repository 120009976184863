#Home {
  padding: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}

#Home > div.ui.segment {
  overflow-x: hidden;
}

#Home .ui.button.basic {
  box-shadow: none;
}

#Home .ui.button.basic:hover {
  background-color: #cacbcd !important;
}

.SplashButton {
  width: 13.4vw;
  height: 13.4vw;
  display: flex;
  flex-direction: column;
  max-width: 196px;
  max-height: 196px;
}

.ui.card .image.SplashIcon {
  height: 15vw;
  max-height: 20vh;
  padding: 0.5em;
  background: none;
}

#HomeBetaLabel {
  text-align: center;
}
