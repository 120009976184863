#PlaylistHorizontalScrollbarContainer {
  width: 100%;
  display: flex;
}

#PlaylistHorizontalScrollbarPad {
  min-width: 11em;
  flex-shrink: 0;
}

#PlaylistHorizontalScrollbar {
  overflow: auto;
  width: 0;
  flex-grow: 1;
}

#PlaylistHorizontalScrollbarInterior {
  height: 1px;
}
