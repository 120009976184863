.globalMusicContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.globalMusicContent__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
}
.globalMusicContent__buttons {
    margin-bottom:20px;
}
.globalMusicContent__details {
    margin-bottom:20px;
    font-size: 20px;
    line-height: 40px;
}
.globalMusicContent__footer {
    margin-top:20px;
}
.globalMusicContent--button {
    margin-right: 20px !important;
}
.libraryComponent {
    width: 100% !important;
    height: 100%;
}
