.PlaylistRegionRow {
  max-height: 8em;
  height: 20%;
  white-space: nowrap;
  display: flex;
  min-height: 4em;
}

.PlaylistRegionRow.selected {
  border: 1px #21ba45 solid;
}

.PlaylistRegion {
  border: solid black 1px;
  border-top: 0px;
  height: 100%;
  width: 11em;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.PlaylistRegion.Timeline {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}
