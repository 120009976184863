.timeInput {
  display: flex;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.24em;
  padding: 0.3em;
  align-items: center;
}

.timeInput.inline {
  display: inline-flex;
}

.ui.form .timeInput input,
.ui.form .timeInput select,
.timeInput input,
.timeInput select {
  border: 0px;
  padding: 0px;
  width: auto;
}

.ui.form .timeInput input:not([type]) {
  border: 0px;
  padding: 0px;
}

.ui.form .timeInput select,
.timeInput select {
  width: auto;
}

.ui.form .timeInput input,
.ui.form .timeInput input:not([type]),
.timeInput input {
  width: 2em;
  text-align: center;
}

.ui.form .timeInput input.millisecondsInput,
.timeInput input.millisecondsInput {
  width: 3em;
  text-align: center;
}

.ui.form .timeInput input:active,
.ui.form .timeInput select:focus,
.ui.form .timeInput select:active,
.timeInput input:active,
.timeInput select:focus,
.timeInput select:active {
  outline: none;
  background-color: lightgrey;
}

.timeInput input[type=number]::-webkit-inner-spin-button,
.timeInput input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timeInput input[type=number] {
  -moz-appearance:textfield;
}

span.unitDivider {
  font-weight: bold;
  font-family: sans-serif;
}

.ui.form span.unitDivider {
  font-size: 1.2em;
}
