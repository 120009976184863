div#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
}

div#content_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 0;
}

button#sidebar_visibility_toggle {
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

div.sidebar_container {
  display: flex;
  flex-direction: row;
  height: 0;
  flex-grow: 1;
}

div.sidebar {
  display: inline-block;
  width: 300px;
  min-width: 300px;
}

div.sidebar_in {
  visibility: hidden;
  width: 0px;
  min-width: 0px;
}

#BetaLabel {
  text-align: center;
  pointer-events: none;
}
