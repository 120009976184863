.tabbedComponentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ui.segment.tabbedComponentPane {
  flex-grow: 1;
  padding: 1em 0 0 0;
  min-height: 0;
}

.icon.tabbedCloseButton {
  font-size: 0.8em !important;
  position: absolute;
  top: 5px;
  left: 5px;
}

.icons.tabbedMenuItemUnsaved {
  margin-left: 0.3em;
  margin-right: -0.5em;
}

.ui.tabular.menu .item.tabbedMenuItem {
  padding-left: 1.8em;
}
