#taskbar {
  background-image: linear-gradient(to right, rgb(48, 93, 170), rgb(75, 182, 73));
}

div#applications_menu > div.text {
  font-size: x-large;
  line-height: 1.8;
}

#taskbar:first-child,
#taskbar:last-child {
  border-radius: 0;
}

.ui.labeled.icon.menu .item > .pin-icon.icon:not(.dropdown),
.ui.labeled.icon.menu .item > .remove-icon.icon:not(.dropdown) {
  font-size: 0.8em !important;
  position: absolute;
  top: 5px;
}

.ui.labeled.icon.menu .item > .pin-icon.icon:not(.dropdown) {
  right: 5px;
  color: rgba(0,0,0,0.4);
}

.ui.labeled.icon.menu .item > .remove-icon.icon:not(.dropdown) {
  left: 5px;
  color: #fff;
}

.ui.label.tabNumbers {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 500rem 0 0 0;
}

.ui.image.menu-icon-svg {
  max-width: 100%;
  max-height: 100%;
}

/*
 * The date and time components had not ONE, BUT /TWO/ LINES! UNACCEPTABLE!! There is only one now.
 */
.ui.blue.small.label.ui.item.date {
  margin: 0;
}

.ui.blue.small.label.ui.item.zone {
  margin: 0;
}

.ui.labeled.icon.menu .item > .pin-icon.pinned.icon:not(.dropdown) {
  color: #fff;
}

#clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0.5em;
}

#timeClock {
  font-weight: bold;
  font-size: 16px;
}

#menu-icons {
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}

#TimeChangeSubmit {
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

#TimeChangeDateTime {
  min-height: 15em;
  display: flex;
  justify-content: center;
}
