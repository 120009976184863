.dateTimeInput {
  display: flex;
}

.dateTimeInput.inline {
  flex-direction: column;
}

.dateTimeInput .react-datepicker-wrapper {
  flex-grow: 1;
}
