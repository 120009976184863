.ui.cards > .card.highlighted {
  font-weight: bold;
  outline: solid 2px #315da9;
  background-color: #c8dfff;
}

.ui.cards > .ui.card.active {
  background: #e0e0e0;
}

.ui.cards > .ui.card.closed {
  border: solid 1px red;
}
