div.metadataName {
  font-size: 1.1em;
  font-weight: bold;
}

div.metadataDescription {
  font-size: small;
  overflow-x: hidden;
  white-space: normal;
}

td.metadataColumn {
  overflow: hidden;
}

div#metadataEditorPane {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

div#metadataTablePane {
  height: 0;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#metadataHeader {
  margin: 0.5em;
}

#metadataFileDisplay {
  padding-left: 0.5em;
}

#metadataButtonPane {
  padding-left: 0.5em;
}

.ui.menu .item.menuDropdown::before {
  display: none;
}
