.frameEditorPanel:hover .Frame.selected {
  z-index: 9999 !important;
}

.FrameContents {
  border: solid 1px white;
  outline: solid 1px black;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.FrameContents.drag:hover {
  cursor: move;
}

.FrameContents.selected {
  border-color: #21ba45;
  outline-color: #21ba45;
}

.FrameImage {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.FrameLabel {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: bold;
  color: white;
  text-shadow:
    -1px -1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    1px 1px 0 #000;
}

.FrameContents.selected .FrameLabel {
  color: #21ba45;
}

.FrameStatusIcons {
  display: inline-block;
  text-shadow:
    -1px -1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    1px 1px 0 #000;
  padding: 3px 0 0 5px;
}

.FrameImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.FrameImageBack {
  display: none;
}
