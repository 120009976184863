#SimpleListEditor {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
}

#SimpleListGrid,
#SimpleListGrid > .column,
#SimpleListPanel {
  max-height: 100%;
  height: 100%;
}

#SimpleListPanel {
  display: flex;
  flex-direction: column;
}

#SimpleListItems {
  flex-grow: 1;
  overflow-y: auto;
}

.ui.menu.item a.item.randomOn {
  background-color: #21ba45;
  color: white;
}

#SimpleListSaveDropdown .item::before {
  display: none;
}
