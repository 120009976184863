.PlaylistFrameClip {
  height: 100%;
  background-color: darkgrey;
  border: 1px solid black;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.PlaylistFrameClip:hover {
  background-color: lightgrey;
}

.PlaylistFrameClip.touchStart {
  border-left-style: dashed;
}

.PlaylistFrameClip.touchEnd {
  border-right-style: dashed;
}

.PlaylistFrameClip.selected {
  border: 2px solid green;
  background-color: #ccc;
}

.PlaylistFrameClipLabel {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  padding: 0 3px 0 3px;
  background-color: rgba(170, 170, 170, 0.5);
  font-weight: bold;
  color: white;
  text-shadow: -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000;
}

div.PlaylistFrameClipContainer {
  height: 100%;
}
