.scrollingText {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.scrollingText__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.scrollingText__MainContainer {
    padding:10px;
    flex-grow: 1;
    height: 100%;
}

.scrollingText__breadcrumb a {
    font-size: 11px;
}
.scrollingText__breadcrumb .active {
    font-size: 11px;
}
.scrollingText__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}

.scrollingText__Row {
    display:flex; 
    flex-direction:row;
    height: 100%;
}

.scrollingText--div {
    width: 50%;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.scrollingText__Row textarea {
    border: 1px solid #CCC;
    padding: 10px;
    width: 95%;
    height: 85%;
    resize: none;
}
.scrollingText--previewDiv {
    position: relative;
    width: 95%;
}
.scrollingText__buttonGroup {
    margin-top: 10px !important; 
    padding-top: 10px !important; 
    border-top: 1px solid #cccccc;
}
.scrollingText__buttonGroup button{
    margin-right: 10px !important;
}
.scrollingText__errorText {
    border: 1px solid #990000;
    background: #fadbdb;
    padding: 6px;
    margin: 10px 0px 20px 0px;
}

.textGridContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}

@media screen and (max-width:1024px) {
    .scrollingText__Row { flex-direction: column;  }
    .scrollingText--div {
        width: 100%;
        position: inherit;
        margin-bottom: 20px;
        flex-basis: 0;
        flex-grow: 1;
    }
    .scrollingText--textarea { height: 400px; }
    .scrollingText--previewDiv { width: 100%; }
    .scrollingText__Row textarea {
        height: 300px;
        width: 100%;
    }
}
