#ServiceSettingsHeader {
  margin: 0.4em;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.ui.menu .item.settingsMenuDropdown::before {
  display: none;
}

#ServiceSettingsHeader .ui.header>.icon {
  font-size: 30px;
  margin-bottom: 5px;
}

#IconOffOrON {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  padding-right: 20px;
  padding-top: 7px;
}
