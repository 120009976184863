#PlaylistEditorPane {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

#PlaylistFrameContent {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

#OtherPlaylistContent {
  width: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

#CastusCutsMenu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#CutsControlsTab,
#CutsLibraryTab {
  flex-grow: 1;
}

#PlaylistTimelineContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

#PlaylistLibraryContainer {
  flex-grow: 1;
  position: relative;
}

#PlaylistControls {
  padding: 1em;
  flex-grow: 1;
}

#PlaylistControls > * {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

#PlaylistControls > .ui.menu.labeled {
  padding: 0;
}
