.PlaylistFrameClipImages {
  height: 100%;
  z-index: 0;
}

.ClipImageSpacer {
  display: inline-block;
  height: 100%;
}

.ClipImageContainer {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  position: absolute;
}

.ClipImage {
  max-height: 100%;
}
