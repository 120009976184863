.ui.card.SplashTile {
  box-shadow: none;
  font-size: 1.3em;
}

.ui.card.SplashTile > .content {
  border-top: none;
}

.ui.card.SplashTile.disabled {
  background-color: #e8e8e8;
}

.ui.card.SplashTile.disabled > .content > .header {
  color: grey;
}

