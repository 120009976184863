div.rdt > div {
  flex-grow: 1;
}

.hasItems,
div.hasItems {
  background-color: #afa;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected.hasItems {
  background-color: #428bca;
  color: white;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div {
  height: 100%;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div > div:hover {
  cursor: pointer;
  background: rgba(0,0,0,.03);
  color: rgba(0,0,0,.95);
}
