div#root, div#app_container {
  height: 100%;
}

.noselect {
  user-select: none !important;
}

.noselect:hover {
  cursor: default;
}

.icon.svg-icon {
  vertical-align: text-bottom;
}

i.icons.svg-icon-group .icon.svg-icon:first-child {
  width: 1.18em;
  vertical-align: text-bottom;
}

i.icons.svg-icon-group .icon.corner.caret.down {
  font-size: 0.8em;
  bottom: -0.8em;
  right: -0.1em;
}

.icon.svg-icon > .ui.image {
  max-width: 100%;
  max-height: 100%;
}

/* hand cursor */
.cursor-pointer {cursor: pointer;}

/* Fixes bug with modals being rendered in the upper-left instead of being centered */
.ui.page.transition.modals.active {
  display: flex !important;
}

.ui.modal {
  margin-top: 0px !important;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 3px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #787878;
  border-radius: 10px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #929292;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #787878;
  border-radius: 10px;
  border: 0px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #5f5f5f;
  border: 0px solid #010101;
  border-radius: 20px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #787878;
  border: 0px solid #010101;
  border-radius: 20px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  height: 10px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #787878;
}
input[type=range]:focus::-ms-fill-upper {
  background: #929292;
}
