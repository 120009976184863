div.BlockControls {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

div.BlockControlsSpacer {
  flex-grow: 1;
}

.BlockOccurence.noselect:hover {
  cursor: pointer;
  color: blue;
}

.blockAssignedSegment {
  overflow-x: hidden;
  word-break: break-word;
}

.blockAssignedSegment:hover {
  cursor: default;
}

div.ui.input.blockColorInput input[type="color"] {
  padding: 0;
  height: auto;
}
