div.scheduleReplicateTimeSelector > div.rdt > div.rdtPicker {
  position: static;
}

div.scheduleReplicateTimeSelector > div.rdt > div.rdtPicker > div.rdtMonths > table > thead {
  visibility: collapse;
}

div.react-datepicker__day--selected.noViewHighlight,
div.react-datepicker__day--keyboard-selected.noViewHighlight {
  background-color: inherit;
  color: black;
}

.isSelected,
div.isSelected,
div.isSelected:hover,
div.react-datepicker__day--selected.isSelected,
div.react-datepicker__day--selected.noViewHighlight.isSelected,
div.react-datepicker__day--keyboard-selected.noViewHighlight.isSelected {
  background-color: #afa;
  color: black;
}

.isBasis,
.isSelected.isBasis,
.react-datepicker__day.isBasis {
  color: white;
  background-color: #216ba5;
}

th.dow:hover {
  cursor: pointer;
  background-color: #eee;
}

.ui.item.menu a.item.hasItems {
  background-color: #9f9;
}

.ui.item.menu a.item.hasItems.active {
  background-color: #093;
  color: white;
}
