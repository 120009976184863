#PlaylistTimeline {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 1em;
  height: 0;
}

#PlaylistTimelineContents {
  display: flex;
  flex-direction:column;
  overflow: hidden;
}

#PlaylistHeader {
  height: 2em;
  display: flex;
  flex-shrink: 0;
}

#PlaylistBody {
  flex-grow: 1;
  min-height: 5em;
  display: flex;
  overflow: hidden;
}

#PlaylistRegionContainer {
  flex-grow: 1;
  width: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
