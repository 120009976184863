#PlaylistTimeHeader {
  width: 11em;
  background-color: darkgrey;
  border: 1px solid black;
  flex-shrink: 0;
  text-align: center;
}

#PlaylistTimeHeaderDisplay {
  vertical-align: middle;
  height: 100%;
  padding: 0;
}
